@import url(//use.fontawesome.com/releases/v5.15.3/css/all.css);
.navbar-top {
    background-color: #FF8C58;
}
.navbar-top button {
    color: #fff;
    border: 0;
    background-color: #FF8C58;
}
.navbar {
    width: 100%;
}
.bottom-tab-nav {
    border-bottom: solid 1px #EBEBEB;
    border-top: solid 1px #EBEBEB;
    background-color: #fff;
}
.nav-link:hover,
.bottom-nav-link:hover {
    color: #555555;
    -webkit-filter: invert(67%) sepia(46%) saturate(1949%) hue-rotate(322deg) brightness(105%) contrast(104%);
            filter: invert(67%) sepia(46%) saturate(1949%) hue-rotate(322deg) brightness(105%) contrast(104%);
}
.nav-link {
    color: #fff;
}
.nav-text {
    color: #fff;
    margin: 8px 0;
    padding: 1px 0;
}
.nav-top-active {
    border: 1px solid #fff;
    border-radius: 25px;
}
.navbar-brand {
    color: #fff!important;
}
.bottom-nav-link {
    color: #555555;
    padding: 0.25rem 1rem!important;
}
    .bottom-nav-link.active {
        -webkit-filter: invert(67%) sepia(46%) saturate(1949%) hue-rotate(322deg) brightness(105%) contrast(104%);
                filter: invert(67%) sepia(46%) saturate(1949%) hue-rotate(322deg) brightness(105%) contrast(104%);
    }
    .bottom-nav-link svg {
        height: 28px;
    }
.bottom-tab-label {
    padding-top: 5px;
    font-size: 12px;
}
.space {
    margin-right: 5px;
}

/* Tablet */
@media only screen and (min-device-width: 769px) {
    .profile-menu {
        margin: -2em auto 2em auto;
    }
}

.back-general {
    z-index: -1;
    margin-top: -60px;
    height: 100vh;
    width: 100%;
    background: 
    linear-gradient(135deg, rgb(150 150 150 / 5%) 25%, transparent 25%) -50px 0,
    linear-gradient(225deg, rgb(150 150 150 / 5%) 25%, transparent 25%) -50px 0,
    linear-gradient(315deg, rgb(150 150 150 / 5%) 25%, transparent 25%),
    linear-gradient(45deg, rgb(150 150 150 / 5%) 25%, transparent 25%);
    background-color: rgb(255, 255, 255);	
    background-size: 2em 2em;
    position: fixed;
}
@-webkit-keyframes ldio-82n6jrgbepw {
  0% { -webkit-transform: rotate(0); transform: rotate(0) }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}

@keyframes ldio-82n6jrgbepw {
  0% { -webkit-transform: rotate(0); transform: rotate(0) }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}

.loadingio-spinner-double-ring-ed3iaftsicn {
  margin-top: 50%;
}

.ldio-82n6jrgbepw div { box-sizing: border-box!important }
.ldio-82n6jrgbepw > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: var(--orange-2) transparent var(--orange-2) transparent;
  -webkit-animation: ldio-82n6jrgbepw 1s linear infinite;
          animation: ldio-82n6jrgbepw 1s linear infinite;
}

.ldio-82n6jrgbepw > div:nth-child(2), .ldio-82n6jrgbepw > div:nth-child(4) {
  width: 54px;
  height: 54px;
  top: 23px;
  left: 23px;
  animation: ldio-82n6jrgbepw 1s linear infinite reverse;
}
.ldio-82n6jrgbepw > div:nth-child(2) {
  border-color: transparent var(--orange-4) transparent var(--orange-4)
}
.ldio-82n6jrgbepw > div:nth-child(3) { border-color: transparent }
.ldio-82n6jrgbepw > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ldio-82n6jrgbepw > div:nth-child(3) div:before, .ldio-82n6jrgbepw > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: var(--orange-2);
  border-radius: 50%;
  box-shadow: 0 64px 0 0 var(--orange-2);
}
.ldio-82n6jrgbepw > div:nth-child(3) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 var(--orange-2);
}

.ldio-82n6jrgbepw > div:nth-child(4) { border-color: transparent; }
.ldio-82n6jrgbepw > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ldio-82n6jrgbepw > div:nth-child(4) div:before, .ldio-82n6jrgbepw > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 15px;
  background: var(--orange-4);
  border-radius: 50%;
  box-shadow: 0 46px 0 0 var(--orange-4);
}
.ldio-82n6jrgbepw > div:nth-child(4) div:after {
  left: -8px;
  top: 15px;
  box-shadow: 46px 0 0 0 var(--orange-4);
}
.loadingio-spinner-double-ring-ed3iaftsicn {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-82n6jrgbepw {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.ldio-82n6jrgbepw div { box-sizing: content-box; }

@media only screen and (min-device-width: 769px) {
  .loadingio-spinner-double-ring-ed3iaftsicn {
      margin-top: 15%;
  }
}
.btn-logout {
    margin-bottom: 100px;
}
.check-text {
    font-size: 1.2em;
    padding-left: 5px;
}

.custom-control-label:before{
    border-color: var(--aqua-1) !important;
    box-shadow:none !important;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: var(--aqua-1);
    border-color: var(--aqua-1);
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}
.img-profile {
    width: 35%;
    max-width: 150px;
}
.profile-menu {
    border: 1px solid #FFF;
    margin: -2em 1.5em 2em 1.5em;
    padding-bottom: 2em;
    max-width: var(--cont-mw-tablet);
}
    .profile-menu li {
        padding: 6px 10px;
        width: 80%;
        margin: 1.25em auto;
        list-style-type: none;
        background-color: #FFF;
        border: 1px solid var(--grey-5);
        display: -webkit-flex;
        display: flex;
        border-radius: 20px;
    }
    .profile-menu li svg {
        margin: 3px 0 0 5px;
    }
    .profile-menu a {
        color: var(--grey-2);
        font-size: 1.125em;
        display: block;
        padding-left: 15px;
        width: 100%;
    }
    .profile-menu a:hover {
        text-decoration: none;
        color: var(--orange-2);
    }

/* Mobile iPhone 12 Pro width */
/* 
@media only screen and (max-device-width: 390px) {
    .profile-menu {
        margin: -2em 1.5em 2em 1.5em;
    }
} */

/* Tablet */
@media only screen and (min-device-width: 395px) {
    .profile-menu {
        margin: -2em auto 2em auto;
        width: 50%;
    }
}
.muscle-item {
    position: relative;
    height: 60px;
    width: 60px;
    display: inline-block;
    margin: 0 1em 0 0;
}
    .muscle-name {
        position: absolute;
        background-color: var(--orange-2);
        border-radius: 15px;
        padding: 0px 6px;
        color: #fff;
        top: 70%;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        opacity: .9;
        font-size: .75em;
    }
    .muscle-img {
        position: absolute;
        max-height: 60px;
    }

/* Desktop */
@media only screen and (min-device-width: 769px) {
    .muscle-name {
        font-size: 1em;
    }
    .muscle-item {
        height: 80px;
        width: 80px;
        margin: 0 2em 0 0;
    }
    .muscle-img {
        max-height: 80px;
    }
}
.log-list li:last-of-type {
    margin-bottom: 80px;
}
.log-list {
    list-style: none;
}
.log-list li {
    position: relative;
    margin: .25em;
    border-bottom: 1px var(--grey-5) solid;
}
.log-list p {
    margin: 0 0 0 .5em;
}
.log-list a {
    color: var(--grey-2);
}
.log-list a:hover {
    color: var(--orange-2);
    text-decoration: none;
}
    .log-record-date {
        position: absolute;
        right: 5%;
        top: 5%;
        color: #FFF;
        background-color: var(--aqua-3);
        padding: 0 8px;
        border-radius: 15px;
    }
    .muscle-list {
        margin-bottom: .5em;
        margin-left: .5em;
    }
     
/* Desktop */
@media only screen and (min-device-width: 391px) {
    .log-list {
        max-width: var(--cont-mw-desktop);
        margin: 0 auto;
    }
        .log-list p {
            font-size: var(--font-s-desktop);
        }
}
.char-list-icon {
    padding-right: 10px;
}
.char-list-item p{
    margin-bottom: 0px;
    padding-top:2px;
}
.char-list-item {
    padding: 10px 0;
}

.character-stats-list a {
    text-decoration: none;
}

.mvp-btn-quest {
    text-align: center;
    padding-bottom: 60px;
}



/* Mobile iPhone 12 Pro width */
/* 
@media only screen and (max-device-width: 390px) {
    .profile-menu {
        margin: -2em 1.5em 2em 1.5em;
    }
} */

/* Tablet */
@media only screen and (min-device-width: 450px) {
    .profile-menu {
        margin: -2em auto 2em auto;
        width: 50%;
    }
}
.cat-card:last-child {
    padding-bottom: 80px;
}
.cat-card {
    display: inline-block;
}
.card {
    border-radius: 20px!important;
    margin: 15px 15px 0 15px;
    height: 180px;
    width: 360px;
    border: 0!important;
}
    .card img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        border-radius: 20px;
    }
    .card p {
        margin: 5px auto;
        position: absolute;
        bottom: 3%;
        left: 5%;
        font-size: 1.2em;
        font-weight: 600;
        opacity: 1;
    }
    .title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        border-radius: 0 0 18px 18px;
    }
    .level-badge {
        position: absolute;
        top: 8%;
        right: 5%;
        background-color: var(--aqua-5);
        padding: 3px 8px;
        line-height: normal;
        border-radius: 20px;
        color: var(--aqua-1);
    }
    .date-badge {
        position: absolute;
        top: 8%;
        right: 5%;
        background-color: var(--orange-5);
        padding: 3px 8px;
        line-height: normal;
        border-radius: 20px;
        color: var(--orange-1);
    }
    .left-badge {
        position: absolute;
        top: 8%;
        left: 5%;
        background-color: var(--aqua-5);
        padding: 3px 8px;
        line-height: normal;
        border-radius: 20px;
        color: var(--aqua-1);
    }


@media only screen and (min-device-width: 1024px) {
    .card {
        width: 540px;
        height: 270px;
        margin: 15px 15px 0 15px;
    }
    .card img {
        height: 270px;
    }
}


.img-center-animated {
    display: block;
    width: 35%;
    max-width: 150px;
    margin: 20px auto;
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
    -webkit-animation: mymove 3s infinite linear;
            animation: mymove 3s infinite linear;
}
.img-req-workout {
    width: 45%;
    max-width: 200px;
}
@-webkit-keyframes mymove {
    50% {
        -webkit-transform: rotate(-8deg);
                transform: rotate(-8deg);
    }
    100% {
        -webkit-transform: rotate(8deg);
                transform: rotate(8deg);
    }
}
@keyframes mymove {
    50% {
        -webkit-transform: rotate(-8deg);
                transform: rotate(-8deg);
    }
    100% {
        -webkit-transform: rotate(8deg);
                transform: rotate(8deg);
    }
}
.btn-request {
    display: block;
    margin: 10px auto;
    margin-bottom: 100px;
}
.top-text {
    color:#FFA982;
    padding: 20px 15px 10px 15px;
    text-align: center;
    font-size: var(--font-s-desktop);
}
.input-text {
    width: 80vw!important;
    resize: none;
}

/* Tablet */
@media only screen and (min-device-width: 769px) {
    .input-text {
        max-width: var(--cont-mw-tablet);
    }
}
.list-wrapper {
    text-align: center;
}
.empty-view-block {
    padding: 10% 5%;
}
.empty-view-block h4 {
    padding-bottom: 20px;
}

.header {
    padding: 10px;
    background-color: var(--orange-2);
    color: #FFF;
}

.image-op {
    opacity: 0.3;
}
.col-title {
    padding-left: 10px;
    font-size: 1em;
    max-width: 60%;
    display: inline-block;
    width: 60%;
    vertical-align: middle;
}
.col-img {
    max-width: 20%;
    height: auto;
}
.superset-indicator {
    position: absolute;
    top: 85%;
    left: 5px;
    z-index: 1;
    background-color: var(--orange-2);
    border-radius: 15px;
    padding: 0px 6px;
    color: #fff;
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
}
.complete-badge {
    position: absolute;
    background-color: var(--aqua-2);
    border-radius: 15px;
    padding: 0px 6px;
    color: #fff;
    top: 70%;
    left: 5px;
}
.ex-header {
    margin-top: 10px;
}

.Collapsible:last-of-type {
    padding-bottom: 80px;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: var(--grey-2);
  max-width: 100vw;
  position: relative;
  padding: 10px;

}
    .Collapsible__trigger:after {
        font-family: 'Font Awesome 5 Free';
        content: '\f107';
        font-weight: 900;
        font-size: 1.2em;
        background-color: var(--orange-2);
        border-radius: 15px;
        padding: 0 9px;
        color: #FFF;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        position: absolute;
        right: 3%;
        top: 50%;
        display: block;
        transition: -webkit-transform 300ms;
        transition: transform 300ms;
        transition: transform 300ms, -webkit-transform 300ms;
    }
    .Collapsible__trigger.is-open:after {
        -webkit-transform: translateY(-65%) rotate(180deg);
                transform: translateY(-65%) rotate(180deg);
        background-color: var(--aqua-3);
    }
    .Collapsible__trigger.is-disabled {
        opacity: 0.5;
        background-color: grey;
    }
.sets {
    padding: 1px 0;
    border-radius: 20px;
    margin: -10px 12px 20px 12px;
    z-index: 1;
    background-color: #FFF;
    max-width: 480px;
    position: relative;
}
    .set-wrapper {
        margin: 15px 10px;
    }
        .set {
            margin-left: 10px;
            display: inline;
        }
        .set:first-child {
            margin-top: 10px;
        }
            .set-number {
                background-color: var(--orange-2);
                padding: 2px 8px;
                border-radius: 15px;
                color: #FFFFFF;
                margin-right: 8px;
            }
            .set-check {
                display: inline!important;
                float: right;
            }
            .superset-note {
                float: right;
                color: var(--aqua-2);
            }
            .superset-icon {
                background-color: var(--aqua-2);
                border-radius: 15px;
                padding: 2px 10px;
                margin-left: 5px;
                color: #fff;
                font-size: 1em;
                line-height: 0.5;
            }
.timer-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
}
    .gradient, .gradient-side {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .exercise img {
        display: block;
        max-width: 100vw;
        margin: 0 auto;
        padding-bottom: 1px;
    }
    .gradient {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 1) 100%);
    }
    .gradient-side {
        background-image: linear-gradient(to right, rgba(253, 255, 255, 1), rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 1) 100%);
    }
    .timer-wrapper span {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 30%;
        text-align: center;
        font-size: 3em;
        z-index: 1;
    }
.ex-description {
    margin: 0 20px 0 20px;
}
.start-btn {
    position: fixed;
    bottom: 4%;
    right: 10%;
    z-index: 3;
}

.stop-btn {
    display: block;
    font-size: 16px;
    margin: -80px auto 48px auto;
    padding: 8px 20px;
    border-radius: 20px;
    border: 0;
    color: #fff;
    background-color: var(--orange-2);
    z-index: 2;
    position: relative;
}
.stop-icon {
    width: 1em;
    height: 1em;
    background-color: #FFF;
}


/* Mobile */
@media only screen and (min-device-width: 481px) {
    .col-img {
        max-width: 75px;
    }
    .sets {
        margin: -10px auto 20px auto;
    }
}
/* Image Gradient fix as image is 540px wide */
@media only screen and (min-device-width: 540px) {
    .gradient, .gradient-side {
        width: 540px;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
}

/* Tablet */
@media only screen and (min-device-width: 769px) {
    .gradient, .gradient-side {
        width: 75.2%;
    }
    .exercise img {
        width: 75%;
    }
    .col-img {
        max-width: 100px;
    }
    .complete-badge {
        left: 18px;
    }
    .superset-indicator {
        top: 90%;
        left: 18px;
    }
    .ex-description {
        max-width: 600px;
        margin: 0 auto;
    }
    .col-title {
        padding-left: 20px;
        font-size: 1.2em;
    }
}
.back {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/auth-back.4eabb104.jpg);
    background-repeat: no-repeat;
    background-position: 10% 60%;
}
.auth-form {
    background-color:#FFF;
    padding: 15px 35px;
    margin-top: 15px;
}
    .auth-form h1 {
        color:var(--orange-2);
    }
.line-input {
    border-width: 0 0 2px 0!important;
    border-radius: 0!important;
}
.orange-link {
    color:var(--orange-2);
}
.signup-text {
    margin-bottom: 10px;
}
.logo-img {
    margin: 0 auto;
    padding-top: 15px;
    display: block;
    max-width: 7%;
}

/* Mobile iPhone 12 Pro width */

@media only screen and (max-device-width: 390px) {
    .logo-img {
        max-width: 25%;
        margin-bottom: 10px;
    }
}

@media only screen and (min-device-width: 1024px) {
    .back {
        background-position: 40% 60%;
    }
    .auth-form {
        background-color:#FFF;
        padding: 20px 35px;
        margin-top: 2%;
    }
    .logo-img {
        padding-top: 2%;
        max-width: 8%;
    }
}
.lead {
    margin-top: 20px;
}

.lead {
    margin-top: 20px;
}

.alert-all {
    padding: 0.8rem 1rem;
    opacity: 0.95;
    text-align: center;
    background: var(--light-color);
    color: #333;
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 10%;
    z-index: 3;
    width: 80%;
    max-width: 500px;
}
.alert-primary {
    background: var(--primary-color);
    color: #fff;
}
.alert-light {
    background: var(--light-color);
    color: #333;
}
.alert-dark {
    background: var(--dark-color);
    color: #fff;
}
.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}
.alert-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}
.alert-white {
    background: #fff;
    color: #333;
    border: #ccc solid 1px;
}

.event-header {
    position: relative;
    height: 100%;
    margin-bottom: 30px;
}
.btn-attend {
    position: absolute;
    bottom: -20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 1.5em;
    text-align: center;
    padding: 2px 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #fff;
    background-color: var(--orange-2);
    border-radius: 20px;
    border: 2px solid #fff;
}
.event-name {
    position: absolute;
    bottom: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    font-size: 1.5em;
    text-align: center;
    opacity: 0.8;
    padding: 0px 12px;
    width: 100%;
    color: #fff;
    background-color: var(--aqua-2);
    /* border-radius: 20px; */
}
.event-date {
    position: absolute;
    top: 10%;
    left: 5%;
    z-index: 1;
    padding: 2px 10px;
    border-radius: 20px;
    border: 0;
    color: #fff;
    background-color: var(--aqua-2);
}
.event-image-overlay {
    position: absolute;
    background-color: var(--orange-1);
    opacity: .2;
    width: 100%;
    height: 100%;
}
.filter-btn {
    display: block;
    margin: 15px auto 0px auto;
    z-index: 144030;
}
.palette-text {
    color: #fff;
}
.row-palette {
    height: 40px;
    margin-bottom: 20px;
}
.or-back-1 {
    background: var(--orange-1);
}
    .or-back-2 {
        background: var(--orange-2);
    }
    .or-back-3 {
        background: var(--orange-3);
    }
    .or-back-4 {
        background: var(--orange-4);
    }
    .or-back-5 {
        background: var(--orange-5);
    }
.aq-back-1 {
    background: var(--aqua-1);
}
    .aq-back-2 {
        background: var(--aqua-2);
    }
    .aq-back-3 {
        background: var(--aqua-3);
    }
    .aq-back-4 {
        background: var(--aqua-4);
    }
    .aq-back-5 {
        background: var(--aqua-5);
    }
.gr-back-1 {
    background: var(--grey-1);
}
    .gr-back-2 {
        background: var(--grey-2);
    }
    .gr-back-3 {
        background: var(--grey-3);
    }
    .gr-back-4 {
        background: var(--grey-4);
    }
    .gr-back-5 {
        background: var(--grey-5);
    }
.bl-back-1 {
    background: var(--blue-1);
}
    .bl-back-2 {
        background: var(--blue-2);
    }
    .bl-back-3 {
        background: var(--blue-3);
    }
    .bl-back-4 {
        background: var(--blue-4);
    }
    .bl-back-5 {
        background: var(--blue-5);
    }
.col-gradient {
    height: 100px;
    border-radius: 20px;
}
.gradient-or {
    background: linear-gradient(135deg, #FF6F2E 0%, #FFE2D5 100%);
}
.gradient-aq {
    background: linear-gradient(134.7deg, #00B3A0 1.22%, #CCF0EC 141.75%);
}

.palette-text {
    color: #fff;
}
.row-palette {
    height: 40px;
    margin-bottom: 20px;
}
.or-back-1 {
    background: var(--orange-1);
}
    .or-back-2 {
        background: var(--orange-2);
    }
    .or-back-3 {
        background: var(--orange-3);
    }
    .or-back-4 {
        background: var(--orange-4);
    }
    .or-back-5 {
        background: var(--orange-5);
    }
.aq-back-1 {
    background: var(--aqua-1);
}
    .aq-back-2 {
        background: var(--aqua-2);
    }
    .aq-back-3 {
        background: var(--aqua-3);
    }
    .aq-back-4 {
        background: var(--aqua-4);
    }
    .aq-back-5 {
        background: var(--aqua-5);
    }
.gr-back-1 {
    background: var(--grey-1);
}
    .gr-back-2 {
        background: var(--grey-2);
    }
    .gr-back-3 {
        background: var(--grey-3);
    }
    .gr-back-4 {
        background: var(--grey-4);
    }
    .gr-back-5 {
        background: var(--grey-5);
    }
.bl-back-1 {
    background: var(--blue-1);
}
    .bl-back-2 {
        background: var(--blue-2);
    }
    .bl-back-3 {
        background: var(--blue-3);
    }
    .bl-back-4 {
        background: var(--blue-4);
    }
    .bl-back-5 {
        background: var(--blue-5);
    }
.col-gradient {
    height: 100px;
    border-radius: 20px;
}
.gradient-or {
    background: linear-gradient(135deg, #FF6F2E 0%, #FFE2D5 100%);
}
.gradient-aq {
    background: linear-gradient(134.7deg, #00B3A0 1.22%, #CCF0EC 141.75%);
}

.ex-list-item {
    padding: 10px 0px 65px 5px;
    color: var(--grey-2);
}
.ex-name {
    position: absolute;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    max-width: 40%;
}
.ex-image {
    margin-top: 20px;
}
.ex-list-description {
    border-left: 3px solid var(--aqua-3);
    padding-left: 10px;
    margin: 20px 0 0 10%;
    color: var(--grey-2);
}
.ex-mus-list {
    float: right;
    padding: 0 40px 0 0;
    max-height: 60px;
}
.ex-list-item:last-child {
    border-bottom: none;
}
.search-input {
    border: none;
    display: block;
    width: 100%;
    background-color: white;
    color: var(--grey-3);
    font-size: 1em; 
    border-radius: 0;
    padding: 10px 0 10px 70px;
    margin-bottom: 20px;
    box-shadow: 1px 1px 15px var(--grey-5);
    background-image: url('https://axrock.s3.amazonaws.com/content/icons/search.svg');
    background-repeat: no-repeat;
    background-position: 3% 50%;
    background-size: contain;
}
.search-input::-webkit-input-placeholder {
    color: var(--grey-3);
}
.search-input:-ms-input-placeholder {
    color: var(--grey-3);
}
.search-input::placeholder {
    color: var(--grey-3);
}
.search-input:focus {
    box-shadow: 2px 2px 15px var(--aqua-5);
    outline-width: 0;
}

/* Desktop */
@media only screen and (min-device-width: 391px) {
    .ex-list-content {
        max-width: var(--cont-mw-desktop);
        margin: 0 auto;
    }
    .search-input {
        width: 60%;
        font-size: var(--font-s-desktop);
        margin: 10px auto;
        border-radius: 15px;
    }
    .ex-mus-list {
        padding: 0 60px 0 0;
    }
}
.score-input {
    width: 40%;
    font-weight: bold;
    margin: 15px 0;
}
.add-row-btn {
    margin: 0px auto;
    margin-bottom: 100px;
    display: block;
}
/*
Landing Page
Image "Workouts for All Levels" - 750*750 jpeg


Image card sizes 720px * 360px (2:1 aspect ratio)

Event image size aspect ratio - approximately 3:1 - check it :D
*/

:root {
    --primary-color: #17a2b8;
    --dark-color: #343a40;
    --light-color: #f4f4f4;
    --danger-color: #dc3545;
    --success-color: #28a745;
    --orange-1: #FF6F2E;
    --orange-2: #FF8C58;
    --orange-3: #FFA982;
    --orange-4: #FFC5AB;
    --orange-5: #FFE2D5;
    --aqua-1: #00B3A0;
    --aqua-2: #33C2B3;
    --aqua-3: #66D1C6;
    --aqua-4: #99E1D9;
    --aqua-5: #CCF0EC;
    --grey-1: #555555;
    --grey-2: #777777;
    --grey-3: #999999;
    --grey-4: #BBBBBB;
    --grey-5: #DDDDDD;
    --blue-1: #12BEF6;
    --blue-2: #41CBF8;
    --blue-3: #71D8FA;
    --blue-4: #A0E5FB;
    --blue-5: #D0F2FD;
    --cont-mw-desktop-lg: 1200px;
    --cont-mw-desktop: 900px;
    --cont-mw-tablet: 700px;
    --font-s-desktop: 1.2em;
}
* {
    padding: 0;
    margin: 0;
    font-family: 'Nunito', sans-serif;
}
.App {
    position: relative;
}
.btn-aqua {
    padding: 8px 30px;
    border-radius: 20px;
    border: 0;
    color: #fff;
    background-color: #33C2B3;
    background-color: var(--aqua-2);
}
.btn-aqua:focus {
    background-color: #99E1D9;
    background-color: var(--aqua-4);
    box-shadow: none!important;
}
.btn-orange {
    padding: 8px 30px;
    border-radius: 20px;
    border: 0;
    color: #fff;
    background-color: #FF8C58;
    background-color: var(--orange-2);
}
.btn-orange:focus {
    background-color: #FFC5AB;
    background-color: var(--orange-4);
    box-shadow: none!important;
}
.btn-auth {
    display: block;
    margin: 0 auto 10px auto;
}
.fullw-img {
    max-width: 100%;
}
.notes-icon {
    font-size: 1em;
    background-color: #66D1C6;
    background-color: var(--aqua-3);
    padding: 0px 9px;
    border-radius: 15px;
    color: #FFFFFF;
    margin-right: 8px;
}
.comments-text {
    border-left: 3px solid #66D1C6;
    border-left: 3px solid var(--aqua-3);
    padding-left: 10px;
}
.gradient-light-orange {
    background: linear-gradient(0deg, rgba(248,225,210,.7) 25%, rgba(248,225,210,0) 80%);
}
.gradient-light-aqua {
    background: linear-gradient(0deg, rgba(204,240,236,.7) 25%, rgba(248,225,210,0) 100%);
}
.gradient-light-blue {
    background: linear-gradient(0deg, rgba(208,242,253,.7) 25%, rgba(248,225,210,0) 100%);
}
.col-orange-1 {
    color: #FF6F2E;
    color: var(--orange-1);
}
.col-aqua-1 {
    color: #00B3A0;
    color: var(--aqua-1);
}
.col-blue-1 {
    color: #12BEF6;
    color: var(--blue-1);
}
.col-grey-1 {
    color: #555555;
    color: var(--grey-1);
}
.col-grey-2 {
    color: #777777;
    color: var(--grey-2);
}
.col-grey-3 {
    color: #999999;
    color: var(--grey-3);
}
.text-aqua {
    color: #33C2B3;
    color: var(--aqua-2);
}
.text-blue {
    color: #41CBF8;
    color: var(--blue-2);
}
.art-header {
    margin: .5em 0 .5em .5em;
}
.art-body {
    margin: .25em 1em 0 1em;
    color: #777777;
    color: var(--grey-2)
}
.white-text-shadow {
    text-shadow: -1px -1px 12px #fff, 1px -1px 12px #fff, -1px 1px 12px #fff, 1px 1px 12px #fff;
}
.pad-bot {
    padding-bottom: 80px;
}
.pad-lr-10 {
    padding: 0 10px 0 10px;
}
/* Blinking button */
@-webkit-keyframes blinking {
    50% {
        background-color: #99E1D9;
        background-color: var(--aqua-4);
    }
    100% {
        background-color: #33C2B3;
        background-color: var(--aqua-2);
    }
}
@keyframes blinking {
    50% {
        background-color: #99E1D9;
        background-color: var(--aqua-4);
    }
    100% {
        background-color: #33C2B3;
        background-color: var(--aqua-2);
    }
}
.btn-animated {
    -webkit-animation: blinking 1s infinite linear;
            animation: blinking 1s infinite linear;
}
/* iOS search clear icon fix */
input[type="search"]::-webkit-search-cancel-button {

    /* Remove default */
    -webkit-appearance: none;
  
    /* Now your own custom styles */
    height: 16px;
    width: 16px;
    display: block;
    padding-left: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    cursor: pointer;

     /* setup all the background tweaks for our custom icon */
    background-repeat: no-repeat;
  
    /* icon size */
    background-size: 16px;
  
}
.content-tablet {
    max-width: 700px;
    max-width: var(--cont-mw-tablet);
    margin: 0 auto;
}
.content-desktop {
    max-width: 900px;
    max-width: var(--cont-mw-desktop);
    margin: 0 auto;
}
.content-desktop-large {
    max-width: 1200px;
    max-width: var(--cont-mw-desktop-lg);
    margin: 0 auto;
}
