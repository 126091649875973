.muscle-item {
    position: relative;
    height: 60px;
    width: 60px;
    display: inline-block;
    margin: 0 1em 0 0;
}
    .muscle-name {
        position: absolute;
        background-color: var(--orange-2);
        border-radius: 15px;
        padding: 0px 6px;
        color: #fff;
        top: 70%;
        left: 50%;
        transform: translateX(-50%);
        opacity: .9;
        font-size: .75em;
    }
    .muscle-img {
        position: absolute;
        max-height: 60px;
    }

/* Desktop */
@media only screen and (min-device-width: 769px) {
    .muscle-name {
        font-size: 1em;
    }
    .muscle-item {
        height: 80px;
        width: 80px;
        margin: 0 2em 0 0;
    }
    .muscle-img {
        max-height: 80px;
    }
}