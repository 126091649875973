.navbar-top {
    background-color: #FF8C58;
}
.navbar-top button {
    color: #fff;
    border: 0;
    background-color: #FF8C58;
}
.navbar {
    width: 100%;
}
.bottom-tab-nav {
    border-bottom: solid 1px #EBEBEB;
    border-top: solid 1px #EBEBEB;
    background-color: #fff;
}
.nav-link:hover,
.bottom-nav-link:hover {
    color: #555555;
    filter: invert(67%) sepia(46%) saturate(1949%) hue-rotate(322deg) brightness(105%) contrast(104%);
}
.nav-link {
    color: #fff;
}
.nav-text {
    color: #fff;
    margin: 8px 0;
    padding: 1px 0;
}
.nav-top-active {
    border: 1px solid #fff;
    border-radius: 25px;
}
.navbar-brand {
    color: #fff!important;
}
.bottom-nav-link {
    color: #555555;
    padding: 0.25rem 1rem!important;
}
    .bottom-nav-link.active {
        filter: invert(67%) sepia(46%) saturate(1949%) hue-rotate(322deg) brightness(105%) contrast(104%);
    }
    .bottom-nav-link svg {
        height: 28px;
    }
.bottom-tab-label {
    padding-top: 5px;
    font-size: 12px;
}
.space {
    margin-right: 5px;
}

/* Tablet */
@media only screen and (min-device-width: 769px) {
    .profile-menu {
        margin: -2em auto 2em auto;
    }
}
