.back {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background-image: url("auth-back.jpg");
    background-repeat: no-repeat;
    background-position: 10% 60%;
}
.auth-form {
    background-color:#FFF;
    padding: 15px 35px;
    margin-top: 15px;
}
    .auth-form h1 {
        color:var(--orange-2);
    }
.line-input {
    border-width: 0 0 2px 0!important;
    border-radius: 0!important;
}
.orange-link {
    color:var(--orange-2);
}
.signup-text {
    margin-bottom: 10px;
}
.logo-img {
    margin: 0 auto;
    padding-top: 15px;
    display: block;
    max-width: 7%;
}

/* Mobile iPhone 12 Pro width */

@media only screen and (max-device-width: 390px) {
    .logo-img {
        max-width: 25%;
        margin-bottom: 10px;
    }
}

@media only screen and (min-device-width: 1024px) {
    .back {
        background-position: 40% 60%;
    }
    .auth-form {
        background-color:#FFF;
        padding: 20px 35px;
        margin-top: 2%;
    }
    .logo-img {
        padding-top: 2%;
        max-width: 8%;
    }
}