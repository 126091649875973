.img-center-animated {
    display: block;
    width: 35%;
    max-width: 150px;
    margin: 20px auto;
    transform: rotate(8deg);
    animation: mymove 3s infinite linear;
}
.img-req-workout {
    width: 45%;
    max-width: 200px;
}
@keyframes mymove {
    50% {
        transform: rotate(-8deg);
    }
    100% {
        transform: rotate(8deg);
    }
}
.btn-request {
    display: block;
    margin: 10px auto;
    margin-bottom: 100px;
}
.top-text {
    color:#FFA982;
    padding: 20px 15px 10px 15px;
    text-align: center;
    font-size: var(--font-s-desktop);
}
.input-text {
    width: 80vw!important;
    resize: none;
}

/* Tablet */
@media only screen and (min-device-width: 769px) {
    .input-text {
        max-width: var(--cont-mw-tablet);
    }
}