.back-general {
    z-index: -1;
    margin-top: -60px;
    height: 100vh;
    width: 100%;
    background: 
    linear-gradient(135deg, rgb(150 150 150 / 5%) 25%, transparent 25%) -50px 0,
    linear-gradient(225deg, rgb(150 150 150 / 5%) 25%, transparent 25%) -50px 0,
    linear-gradient(315deg, rgb(150 150 150 / 5%) 25%, transparent 25%),
    linear-gradient(45deg, rgb(150 150 150 / 5%) 25%, transparent 25%);
    background-color: rgb(255, 255, 255);	
    background-size: 2em 2em;
    position: fixed;
}