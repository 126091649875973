.ex-list-item {
    padding: 10px 0px 65px 5px;
    color: var(--grey-2);
}
.ex-name {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    max-width: 40%;
}
.ex-image {
    margin-top: 20px;
}
.ex-list-description {
    border-left: 3px solid var(--aqua-3);
    padding-left: 10px;
    margin: 20px 0 0 10%;
    color: var(--grey-2);
}
.ex-mus-list {
    float: right;
    padding: 0 40px 0 0;
    max-height: 60px;
}
.ex-list-item:last-child {
    border-bottom: none;
}
.search-input {
    border: none;
    display: block;
    width: 100%;
    background-color: white;
    color: var(--grey-3);
    font-size: 1em; 
    border-radius: 0;
    padding: 10px 0 10px 70px;
    margin-bottom: 20px;
    box-shadow: 1px 1px 15px var(--grey-5);
    background-image: url('https://axrock.s3.amazonaws.com/content/icons/search.svg');
    background-repeat: no-repeat;
    background-position: 3% 50%;
    background-size: contain;
}
.search-input::placeholder {
    color: var(--grey-3);
}
.search-input:focus {
    box-shadow: 2px 2px 15px var(--aqua-5);
    outline-width: 0;
}

/* Desktop */
@media only screen and (min-device-width: 391px) {
    .ex-list-content {
        max-width: var(--cont-mw-desktop);
        margin: 0 auto;
    }
    .search-input {
        width: 60%;
        font-size: var(--font-s-desktop);
        margin: 10px auto;
        border-radius: 15px;
    }
    .ex-mus-list {
        padding: 0 60px 0 0;
    }
}