.alert-all {
    padding: 0.8rem 1rem;
    opacity: 0.95;
    text-align: center;
    background: var(--light-color);
    color: #333;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    z-index: 3;
    width: 80%;
    max-width: 500px;
}
.alert-primary {
    background: var(--primary-color);
    color: #fff;
}
.alert-light {
    background: var(--light-color);
    color: #333;
}
.alert-dark {
    background: var(--dark-color);
    color: #fff;
}
.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}
.alert-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}
.alert-white {
    background: #fff;
    color: #333;
    border: #ccc solid 1px;
}
