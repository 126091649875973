@keyframes ldio-82n6jrgbepw {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}

.loadingio-spinner-double-ring-ed3iaftsicn {
  margin-top: 50%;
}

.ldio-82n6jrgbepw div { box-sizing: border-box!important }
.ldio-82n6jrgbepw > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: var(--orange-2) transparent var(--orange-2) transparent;
  animation: ldio-82n6jrgbepw 1s linear infinite;
}

.ldio-82n6jrgbepw > div:nth-child(2), .ldio-82n6jrgbepw > div:nth-child(4) {
  width: 54px;
  height: 54px;
  top: 23px;
  left: 23px;
  animation: ldio-82n6jrgbepw 1s linear infinite reverse;
}
.ldio-82n6jrgbepw > div:nth-child(2) {
  border-color: transparent var(--orange-4) transparent var(--orange-4)
}
.ldio-82n6jrgbepw > div:nth-child(3) { border-color: transparent }
.ldio-82n6jrgbepw > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-82n6jrgbepw > div:nth-child(3) div:before, .ldio-82n6jrgbepw > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: var(--orange-2);
  border-radius: 50%;
  box-shadow: 0 64px 0 0 var(--orange-2);
}
.ldio-82n6jrgbepw > div:nth-child(3) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 var(--orange-2);
}

.ldio-82n6jrgbepw > div:nth-child(4) { border-color: transparent; }
.ldio-82n6jrgbepw > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-82n6jrgbepw > div:nth-child(4) div:before, .ldio-82n6jrgbepw > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 15px;
  background: var(--orange-4);
  border-radius: 50%;
  box-shadow: 0 46px 0 0 var(--orange-4);
}
.ldio-82n6jrgbepw > div:nth-child(4) div:after {
  left: -8px;
  top: 15px;
  box-shadow: 46px 0 0 0 var(--orange-4);
}
.loadingio-spinner-double-ring-ed3iaftsicn {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-82n6jrgbepw {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-82n6jrgbepw div { box-sizing: content-box; }

@media only screen and (min-device-width: 769px) {
  .loadingio-spinner-double-ring-ed3iaftsicn {
      margin-top: 15%;
  }
}