@import url("//use.fontawesome.com/releases/v5.15.3/css/all.css");
.header {
    padding: 10px;
    background-color: var(--orange-2);
    color: #FFF;
}

.image-op {
    opacity: 0.3;
}
.col-title {
    padding-left: 10px;
    font-size: 1em;
    max-width: 60%;
    display: inline-block;
    width: 60%;
    vertical-align: middle;
}
.col-img {
    max-width: 20%;
    height: auto;
}
.superset-indicator {
    position: absolute;
    top: 85%;
    left: 5px;
    z-index: 1;
    background-color: var(--orange-2);
    border-radius: 15px;
    padding: 0px 6px;
    color: #fff;
    transform: rotate(-15deg);
}
.complete-badge {
    position: absolute;
    background-color: var(--aqua-2);
    border-radius: 15px;
    padding: 0px 6px;
    color: #fff;
    top: 70%;
    left: 5px;
}
.ex-header {
    margin-top: 10px;
}

.Collapsible:last-of-type {
    padding-bottom: 80px;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: var(--grey-2);
  max-width: 100vw;
  position: relative;
  padding: 10px;

}
    .Collapsible__trigger:after {
        font-family: 'Font Awesome 5 Free';
        content: '\f107';
        font-weight: 900;
        font-size: 1.2em;
        background-color: var(--orange-2);
        border-radius: 15px;
        padding: 0 9px;
        color: #FFF;
        transform: translateY(-50%);
        position: absolute;
        right: 3%;
        top: 50%;
        display: block;
        transition: transform 300ms;
    }
    .Collapsible__trigger.is-open:after {
        transform: translateY(-65%) rotate(180deg);
        background-color: var(--aqua-3);
    }
    .Collapsible__trigger.is-disabled {
        opacity: 0.5;
        background-color: grey;
    }
.sets {
    padding: 1px 0;
    border-radius: 20px;
    margin: -10px 12px 20px 12px;
    z-index: 1;
    background-color: #FFF;
    max-width: 480px;
    position: relative;
}
    .set-wrapper {
        margin: 15px 10px;
    }
        .set {
            margin-left: 10px;
            display: inline;
        }
        .set:first-child {
            margin-top: 10px;
        }
            .set-number {
                background-color: var(--orange-2);
                padding: 2px 8px;
                border-radius: 15px;
                color: #FFFFFF;
                margin-right: 8px;
            }
            .set-check {
                display: inline!important;
                float: right;
            }
            .superset-note {
                float: right;
                color: var(--aqua-2);
            }
            .superset-icon {
                background-color: var(--aqua-2);
                border-radius: 15px;
                padding: 2px 10px;
                margin-left: 5px;
                color: #fff;
                font-size: 1em;
                line-height: 0.5;
            }
.timer-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
}
    .gradient, .gradient-side {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .exercise img {
        display: block;
        max-width: 100vw;
        margin: 0 auto;
        padding-bottom: 1px;
    }
    .gradient {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 1) 100%);
    }
    .gradient-side {
        background-image: linear-gradient(to right, rgba(253, 255, 255, 1), rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 1) 100%);
    }
    .timer-wrapper span {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 30%;
        text-align: center;
        font-size: 3em;
        z-index: 1;
    }
.ex-description {
    margin: 0 20px 0 20px;
}
.start-btn {
    position: fixed;
    bottom: 4%;
    right: 10%;
    z-index: 3;
}

.stop-btn {
    display: block;
    font-size: 16px;
    margin: -80px auto 48px auto;
    padding: 8px 20px;
    border-radius: 20px;
    border: 0;
    color: #fff;
    background-color: var(--orange-2);
    z-index: 2;
    position: relative;
}
.stop-icon {
    width: 1em;
    height: 1em;
    background-color: #FFF;
}


/* Mobile */
@media only screen and (min-device-width: 481px) {
    .col-img {
        max-width: 75px;
    }
    .sets {
        margin: -10px auto 20px auto;
    }
}
/* Image Gradient fix as image is 540px wide */
@media only screen and (min-device-width: 540px) {
    .gradient, .gradient-side {
        width: 540px;
        left: 50%;
        transform: translateX(-50%);
    }
}

/* Tablet */
@media only screen and (min-device-width: 769px) {
    .gradient, .gradient-side {
        width: 75.2%;
    }
    .exercise img {
        width: 75%;
    }
    .col-img {
        max-width: 100px;
    }
    .complete-badge {
        left: 18px;
    }
    .superset-indicator {
        top: 90%;
        left: 18px;
    }
    .ex-description {
        max-width: 600px;
        margin: 0 auto;
    }
    .col-title {
        padding-left: 20px;
        font-size: 1.2em;
    }
}