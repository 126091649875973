.btn-logout {
    margin-bottom: 100px;
}
.check-text {
    font-size: 1.2em;
    padding-left: 5px;
}

.custom-control-label:before{
    border-color: var(--aqua-1) !important;
    box-shadow:none !important;
    transform: scale(1.5);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: var(--aqua-1);
    border-color: var(--aqua-1);
    transform: scale(1.5);
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    transform: scale(1.5);
}
.img-profile {
    width: 35%;
    max-width: 150px;
}
.profile-menu {
    border: 1px solid #FFF;
    margin: -2em 1.5em 2em 1.5em;
    padding-bottom: 2em;
    max-width: var(--cont-mw-tablet);
}
    .profile-menu li {
        padding: 6px 10px;
        width: 80%;
        margin: 1.25em auto;
        list-style-type: none;
        background-color: #FFF;
        border: 1px solid var(--grey-5);
        display: flex;
        border-radius: 20px;
    }
    .profile-menu li svg {
        margin: 3px 0 0 5px;
    }
    .profile-menu a {
        color: var(--grey-2);
        font-size: 1.125em;
        display: block;
        padding-left: 15px;
        width: 100%;
    }
    .profile-menu a:hover {
        text-decoration: none;
        color: var(--orange-2);
    }

/* Mobile iPhone 12 Pro width */
/* 
@media only screen and (max-device-width: 390px) {
    .profile-menu {
        margin: -2em 1.5em 2em 1.5em;
    }
} */

/* Tablet */
@media only screen and (min-device-width: 395px) {
    .profile-menu {
        margin: -2em auto 2em auto;
        width: 50%;
    }
}