.log-list li:last-of-type {
    margin-bottom: 80px;
}
.log-list {
    list-style: none;
}
.log-list li {
    position: relative;
    margin: .25em;
    border-bottom: 1px var(--grey-5) solid;
}
.log-list p {
    margin: 0 0 0 .5em;
}
.log-list a {
    color: var(--grey-2);
}
.log-list a:hover {
    color: var(--orange-2);
    text-decoration: none;
}
    .log-record-date {
        position: absolute;
        right: 5%;
        top: 5%;
        color: #FFF;
        background-color: var(--aqua-3);
        padding: 0 8px;
        border-radius: 15px;
    }
    .muscle-list {
        margin-bottom: .5em;
        margin-left: .5em;
    }
     
/* Desktop */
@media only screen and (min-device-width: 391px) {
    .log-list {
        max-width: var(--cont-mw-desktop);
        margin: 0 auto;
    }
        .log-list p {
            font-size: var(--font-s-desktop);
        }
}