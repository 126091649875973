.event-header {
    position: relative;
    height: 100%;
    margin-bottom: 30px;
}
.btn-attend {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5em;
    text-align: center;
    padding: 2px 14px;
    width: fit-content;
    color: #fff;
    background-color: var(--orange-2);
    border-radius: 20px;
    border: 2px solid #fff;
}
.event-name {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 1.5em;
    text-align: center;
    opacity: 0.8;
    padding: 0px 12px;
    width: 100%;
    color: #fff;
    background-color: var(--aqua-2);
    /* border-radius: 20px; */
}
.event-date {
    position: absolute;
    top: 10%;
    left: 5%;
    z-index: 1;
    padding: 2px 10px;
    border-radius: 20px;
    border: 0;
    color: #fff;
    background-color: var(--aqua-2);
}
.event-image-overlay {
    position: absolute;
    background-color: var(--orange-1);
    opacity: .2;
    width: 100%;
    height: 100%;
}
.filter-btn {
    display: block;
    margin: 15px auto 0px auto;
    z-index: 144030;
}