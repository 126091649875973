.palette-text {
    color: #fff;
}
.row-palette {
    height: 40px;
    margin-bottom: 20px;
}
.or-back-1 {
    background: var(--orange-1);
}
    .or-back-2 {
        background: var(--orange-2);
    }
    .or-back-3 {
        background: var(--orange-3);
    }
    .or-back-4 {
        background: var(--orange-4);
    }
    .or-back-5 {
        background: var(--orange-5);
    }
.aq-back-1 {
    background: var(--aqua-1);
}
    .aq-back-2 {
        background: var(--aqua-2);
    }
    .aq-back-3 {
        background: var(--aqua-3);
    }
    .aq-back-4 {
        background: var(--aqua-4);
    }
    .aq-back-5 {
        background: var(--aqua-5);
    }
.gr-back-1 {
    background: var(--grey-1);
}
    .gr-back-2 {
        background: var(--grey-2);
    }
    .gr-back-3 {
        background: var(--grey-3);
    }
    .gr-back-4 {
        background: var(--grey-4);
    }
    .gr-back-5 {
        background: var(--grey-5);
    }
.bl-back-1 {
    background: var(--blue-1);
}
    .bl-back-2 {
        background: var(--blue-2);
    }
    .bl-back-3 {
        background: var(--blue-3);
    }
    .bl-back-4 {
        background: var(--blue-4);
    }
    .bl-back-5 {
        background: var(--blue-5);
    }
.col-gradient {
    height: 100px;
    border-radius: 20px;
}
.gradient-or {
    background: linear-gradient(135deg, #FF6F2E 0%, #FFE2D5 100%);
}
.gradient-aq {
    background: linear-gradient(134.7deg, #00B3A0 1.22%, #CCF0EC 141.75%);
}
