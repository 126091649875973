/*
Landing Page
Image "Workouts for All Levels" - 750*750 jpeg


Image card sizes 720px * 360px (2:1 aspect ratio)

Event image size aspect ratio - approximately 3:1 - check it :D
*/

:root {
    --primary-color: #17a2b8;
    --dark-color: #343a40;
    --light-color: #f4f4f4;
    --danger-color: #dc3545;
    --success-color: #28a745;
    --orange-1: #FF6F2E;
    --orange-2: #FF8C58;
    --orange-3: #FFA982;
    --orange-4: #FFC5AB;
    --orange-5: #FFE2D5;
    --aqua-1: #00B3A0;
    --aqua-2: #33C2B3;
    --aqua-3: #66D1C6;
    --aqua-4: #99E1D9;
    --aqua-5: #CCF0EC;
    --grey-1: #555555;
    --grey-2: #777777;
    --grey-3: #999999;
    --grey-4: #BBBBBB;
    --grey-5: #DDDDDD;
    --blue-1: #12BEF6;
    --blue-2: #41CBF8;
    --blue-3: #71D8FA;
    --blue-4: #A0E5FB;
    --blue-5: #D0F2FD;
    --cont-mw-desktop-lg: 1200px;
    --cont-mw-desktop: 900px;
    --cont-mw-tablet: 700px;
    --font-s-desktop: 1.2em;
}
* {
    padding: 0;
    margin: 0;
    font-family: 'Nunito', sans-serif;
}
.App {
    position: relative;
}
.btn-aqua {
    padding: 8px 30px;
    border-radius: 20px;
    border: 0;
    color: #fff;
    background-color: var(--aqua-2);
}
.btn-aqua:focus {
    background-color: var(--aqua-4);
    box-shadow: none!important;
}
.btn-orange {
    padding: 8px 30px;
    border-radius: 20px;
    border: 0;
    color: #fff;
    background-color: var(--orange-2);
}
.btn-orange:focus {
    background-color: var(--orange-4);
    box-shadow: none!important;
}
.btn-auth {
    display: block;
    margin: 0 auto 10px auto;
}
.fullw-img {
    max-width: 100%;
}
.notes-icon {
    font-size: 1em;
    background-color: var(--aqua-3);
    padding: 0px 9px;
    border-radius: 15px;
    color: #FFFFFF;
    margin-right: 8px;
}
.comments-text {
    border-left: 3px solid var(--aqua-3);
    padding-left: 10px;
}
.gradient-light-orange {
    background: linear-gradient(0deg, rgba(248,225,210,.7) 25%, rgba(248,225,210,0) 80%);
}
.gradient-light-aqua {
    background: linear-gradient(0deg, rgba(204,240,236,.7) 25%, rgba(248,225,210,0) 100%);
}
.gradient-light-blue {
    background: linear-gradient(0deg, rgba(208,242,253,.7) 25%, rgba(248,225,210,0) 100%);
}
.col-orange-1 {
    color: var(--orange-1);
}
.col-aqua-1 {
    color: var(--aqua-1);
}
.col-blue-1 {
    color: var(--blue-1);
}
.col-grey-1 {
    color: var(--grey-1);
}
.col-grey-2 {
    color: var(--grey-2);
}
.col-grey-3 {
    color: var(--grey-3);
}
.text-aqua {
    color: var(--aqua-2);
}
.text-blue {
    color: var(--blue-2);
}
.art-header {
    margin: .5em 0 .5em .5em;
}
.art-body {
    margin: .25em 1em 0 1em;
    color: var(--grey-2)
}
.white-text-shadow {
    text-shadow: -1px -1px 12px #fff, 1px -1px 12px #fff, -1px 1px 12px #fff, 1px 1px 12px #fff;
}
.pad-bot {
    padding-bottom: 80px;
}
.pad-lr-10 {
    padding: 0 10px 0 10px;
}
/* Blinking button */
@keyframes blinking {
    50% {
        background-color: var(--aqua-4);
    }
    100% {
        background-color: var(--aqua-2);
    }
}
.btn-animated {
    animation: blinking 1s infinite linear;
}
/* iOS search clear icon fix */
input[type="search"]::-webkit-search-cancel-button {

    /* Remove default */
    -webkit-appearance: none;
  
    /* Now your own custom styles */
    height: 16px;
    width: 16px;
    display: block;
    padding-left: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    cursor: pointer;

     /* setup all the background tweaks for our custom icon */
    background-repeat: no-repeat;
  
    /* icon size */
    background-size: 16px;
  
}
.content-tablet {
    max-width: var(--cont-mw-tablet);
    margin: 0 auto;
}
.content-desktop {
    max-width: var(--cont-mw-desktop);
    margin: 0 auto;
}
.content-desktop-large {
    max-width: var(--cont-mw-desktop-lg);
    margin: 0 auto;
}