.cat-card:last-child {
    padding-bottom: 80px;
}
.cat-card {
    display: inline-block;
}
.card {
    border-radius: 20px!important;
    margin: 15px 15px 0 15px;
    height: 180px;
    width: 360px;
    border: 0!important;
}
    .card img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        border-radius: 20px;
    }
    .card p {
        margin: 5px auto;
        position: absolute;
        bottom: 3%;
        left: 5%;
        font-size: 1.2em;
        font-weight: 600;
        opacity: 1;
    }
    .title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        border-radius: 0 0 18px 18px;
    }
    .level-badge {
        position: absolute;
        top: 8%;
        right: 5%;
        background-color: var(--aqua-5);
        padding: 3px 8px;
        line-height: normal;
        border-radius: 20px;
        color: var(--aqua-1);
    }
    .date-badge {
        position: absolute;
        top: 8%;
        right: 5%;
        background-color: var(--orange-5);
        padding: 3px 8px;
        line-height: normal;
        border-radius: 20px;
        color: var(--orange-1);
    }
    .left-badge {
        position: absolute;
        top: 8%;
        left: 5%;
        background-color: var(--aqua-5);
        padding: 3px 8px;
        line-height: normal;
        border-radius: 20px;
        color: var(--aqua-1);
    }


@media only screen and (min-device-width: 1024px) {
    .card {
        width: 540px;
        height: 270px;
        margin: 15px 15px 0 15px;
    }
    .card img {
        height: 270px;
    }
}

