.char-list-icon {
    padding-right: 10px;
}
.char-list-item p{
    margin-bottom: 0px;
    padding-top:2px;
}
.char-list-item {
    padding: 10px 0;
}

.character-stats-list a {
    text-decoration: none;
}

.mvp-btn-quest {
    text-align: center;
    padding-bottom: 60px;
}



/* Mobile iPhone 12 Pro width */
/* 
@media only screen and (max-device-width: 390px) {
    .profile-menu {
        margin: -2em 1.5em 2em 1.5em;
    }
} */

/* Tablet */
@media only screen and (min-device-width: 450px) {
    .profile-menu {
        margin: -2em auto 2em auto;
        width: 50%;
    }
}